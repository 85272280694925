<template>
  <section class="mb-16">
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <!-- end appbar nav icon -->
      <v-toolbar-title class="headline font-weight-semibold"
        >Challenges ({{ listMeta.total || '...' }})</v-toolbar-title
      >

      <!-- end toolbar title -->
      <v-spacer />
      <!-- end -->
      <v-row v-show="showSearch" align="center" style="max-width: 370px">
        <v-text-field
          class="search"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search challenge title or tag line"
          v-model="filter.search"
          @input="search"
        ></v-text-field>
      </v-row>

      <v-btn class="ml-5" icon @click="showSearchField">
        <v-progress-circular
          indeterminate
          v-if="isLoading"
        ></v-progress-circular>

        <img src="@/assets/icons/search.svg" alt height="17.7px" v-else />
      </v-btn>
    </v-app-bar>
    <!-- end app bar -->

    <div
      v-infinite-scroll="getList"
      :infinite-scroll-disabled="isLoading"
      infinite-scroll-distance="100"
    >
      <v-data-table
        :headers="headers"
        :items="list"
        :options.sync="options"
        :server-items-length="listMeta.total"
        :loading="isLoading"
        multi-sort
        hide-default-footer
        disable-pagination
        class="clickable"
        @click:row="
          (item) =>
            $router.push({
              name: 'challenge.details',
              params: { id: item.id },
            })
        "
      >
        <template #item.mission="{ item }">
          {{ item.mission.title }}
        </template>
        <template #item.start_date="{ item }">
          {{ item.startDateDisplay }}
        </template>
        <template #item.end_date="{ item }">
          {{ item.endDateDisplay }}
        </template>
        <template #item.is_initiation="{ item }">
          {{ item.is_initiation ? 'Yes' : 'No' }}
        </template>
      </v-data-table>
    </div>

    <v-btn
      id="add-resource-button"
      color="primary"
      large
      dark
      rounded
      elevation="16"
      :to="{ name: 'challenge.new' }"
    >
      <v-icon>
        {{ icons.add }}
      </v-icon>
      <span class="ml-2">Add Challenge</span>
    </v-btn>
  </section>
  <!-- end section -->
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import { mapState } from 'vuex'
import debounce from 'lodash/debounce'
import { mdiPlusCircleOutline } from '@mdi/js'

const VUEX_NAMESPACE = 'challenge'

export default {
  name: 'ChallengesPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      showSearch: false,
      showForm: false,
      isLoading: false,
      icons: {
        add: mdiPlusCircleOutline,
      },
      filter: {
        search: '',
      },
      headers: [
        {
          text: '#',
          value: 'row_number',
          sortable: false,
          width: '5%',
        },
        {
          text: 'Mission',
          value: 'mission',
          sortable: true,
          width: '15%',
        },
        {
          text: 'Challenge',
          value: 'title',
          sortable: true,
          width: '15%',
        },
        {
          text: 'Tag line',
          value: 'tagline',
          sortable: true,
          width: '15%',
        },
        {
          text: 'Start Date',
          value: 'start_date',
          sortable: true,
          width: '15%',
        },
        {
          text: 'End Date',
          value: 'end_date',
          sortable: true,
          width: '15%',
        },
        {
          text: 'Is Initiation?',
          value: 'is_initiation',
          sortable: false,
          width: '15%',
        },
      ],
    }
  },

  mounted() {},

  computed: {
    ...mapState({
      list: (state) => state[VUEX_NAMESPACE].list,
      listMeta: (state) => state[VUEX_NAMESPACE].listMeta,
    }),
    loadedAllItems() {
      return this.listMeta.current_page >= this.listMeta.last_page
    },
    options: {
      get() {
        return this.$store.state[VUEX_NAMESPACE].options
      },
      set(options) {
        this.$store.commit(VUEX_NAMESPACE + '/setOptions', options)
      },
    },
  },

  methods: {
    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },

    async getList() {
      if (this.isLoading) return
      this.isLoading = true
      await this.$store.dispatch(VUEX_NAMESPACE + '/getList', {
        filter: this.filter,
      })
      this.isLoading = false
    },

    clearAndGetList() {
      this.$store.commit(VUEX_NAMESPACE + '/clearList')
      this.getList()
    },

    search: debounce(function () {
      this.clearAndGetList()
    }, 600),
  },

  watch: {
    options: {
      handler() {
        this.clearAndGetList()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
#add-resource-button {
  position: fixed;
  bottom: 64px;
  right: 64px;
}

table {
  border-collapse: separate;
  border-spacing: 0 1em;
}
</style>
