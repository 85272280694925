<template>
  <section class="mb-16">
    <div
      v-infinite-scroll="getList"
      :infinite-scroll-disabled="isLoading"
      infinite-scroll-distance="100"
    >
      <v-data-table
        :headers="headers"
        :items="list"
        :options.sync="options"
        :server-items-length="listMeta.total"
        :loading="isLoading"
        multi-sort
        hide-default-footer
        disable-pagination
        class="clickable"
        @click:row="rowClick"
      >
        <template #item.organisation="{ item }">
          {{ item.orgName }}
        </template>
        <template #item.mission="{ item }">
          {{ item.mission.title }}
        </template>
        <template #item.start_date="{ item }">
          {{ item.startDateDisplay }}
        </template>
        <template #item.end_date="{ item }">
          {{ item.endDateDisplay }}
        </template>
        <template #item.is_initiation="{ item }">
          {{ item.is_initiation ? 'Yes' : 'No' }}
        </template>
      </v-data-table>
    </div>
  </section>
  <!-- end section -->
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll'
import { mapState } from 'vuex'

const VUEX_NAMESPACE = 'challenge'

export default {
  name: 'ChallengesPage',

  directives: {
    infiniteScroll
  },

  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: '#',
          value: 'row_number',
          sortable: false,
          width: '5%'
        },
        {
          text: 'Organisation',
          value: 'organisation',
          sortable: false,
          width: '15%'
        },
        {
          text: 'Mission',
          value: 'mission',
          sortable: true,
          width: '15%'
        },
        {
          text: 'Challenge',
          value: 'title',
          sortable: true,
          width: '15%'
        },
        {
          text: 'Tag line',
          value: 'tagline',
          sortable: true,
          width: '20%'
        },
        {
          text: 'Start Date',
          value: 'start_date',
          sortable: true,
          width: '10%'
        },
        {
          text: 'End Date',
          value: 'end_date',
          sortable: true,
          width: '10%'
        },
        {
          text: 'Is Initiation?',
          value: 'is_initiation',
          sortable: false,
          width: '5%'
        }
      ]
    }
  },

  mounted() {},

  computed: {
    ...mapState({
      list: state => state[VUEX_NAMESPACE].list,
      listMeta: state => state[VUEX_NAMESPACE].listMeta
    }),
    loadedAllItems() {
      return this.listMeta.current_page >= this.listMeta.last_page
    },
    options: {
      get() {
        return this.$store.state[VUEX_NAMESPACE].options
      },
      set(options) {
        this.$store.commit(VUEX_NAMESPACE + '/setOptions', options)
      }
    }
  },

  methods: {
    async getList() {
      if (this.isLoading) return
      this.isLoading = true

      let filter = { parent: this.$route.params.id }

      await this.$store.dispatch(VUEX_NAMESPACE + '/getList', { filter })
      this.isLoading = false
    },

    clearAndGetList() {
      this.$store.commit(VUEX_NAMESPACE + '/clearList')
      this.getList()
    },

    async rowClick(item) {
      this.$router.push({
        name: 'challenge.details',
        params: { id: item.id }
      })
    }
  },

  watch: {
    options: {
      handler() {
        this.clearAndGetList()
      },
      deep: true
    }
  }
}
</script>

<style scoped>
#add-resource-button {
  position: fixed;
  bottom: 64px;
  right: 64px;
}

table {
  border-collapse: separate;
  border-spacing: 0 1em;
}
</style>
