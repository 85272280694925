<template>
  <v-dialog :value="value" max-width="60%" persistent>
    <v-card class="pa-4">
      <v-card-title class="headline">
        Create Child Challenge
      </v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="organisation"
          :items="organisations"
          item-text="name"
          item-value="id"
          no-data-text="Organisation not found"
          label="Select an Organisation"
        ></v-autocomplete>
      </v-card-text>
      <v-card-actions class="pa16">
        <v-btn
          @click="close"
          color="red"
          width="10%"
          dark
          depressed
          class="px-6"
          >Close</v-btn
        >
        <v-spacer />
        <v-btn
          @click="save"
          width="10%"
          depressed
          color="yellow"
          :disabled="submittable"
          :loading="loading"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  props: {
    value: Boolean
  },

  data() {
    return {
      organisation: null,
      loading: false,
      filter: ''
    }
  },

  computed: {
    ...mapState({
      organisations: state => state.organisation.list
    }),
    submittable() {
      return this.organisation == null
    }
  },

  methods: {
    ...mapActions({
      getOrganisations: 'organisation/getOrganisations'
    }),

    ...mapMutations({
      callSnackbar: 'callSnackbar'
    }),

    async save() {
      this.loading = true
      let formData = new FormData()
      formData.append('organisation_id', this.organisation)

      await this.$api
        .post('/challenges/' + this.$route.params.id + '/child', formData)
        .then(() => {
          this.callSnackbar({
            state: true,
            text: 'Successfully created a new challenge',
            color: 'green'
          })
          this.close()
        })
        .catch(err => {
          let message = 'Something went wrong'

          if (err.response.data.error_code === 'DUPLICATE_CHALLENGE') {
            message = 'The organisation already have this challenge'
          }
          if (err.response.data.error_code === 'INVALID_CHALLENGE') {
            message = 'This is not a public challenge'
          }

          this.callSnackbar({
            state: true,
            text: message,
            color: 'red'
          })
          this.close()
        })

      this.loading = false
    },

    close() {
      this.$emit('close')
    }
  },

  watch: {
    value(newValue) {
      if (newValue === true) {
        this.organisation = null
        this.getOrganisations({ type: 'all' })
      }
    }
  }
}
</script>

<style></style>
