<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <router-link class="text-decoration--none" :to="{ name: 'challenges' }">
          <div>Challenge</div>
        </router-link>
        <v-icon>{{ icons.arrowRight }}</v-icon>
        {{ challenge ? challenge.title : '' }}
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4" v-if="challenge">
      <div class="display-2 font-weight-bold my-6 text-capitalize">
        {{ challenge.title }}
      </div>

      <div class="d-flex my-6">
        <v-tabs hide-slider class="organisation-detail-tabs" color="yellow">
          <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">{{
            item.tab
          }}</v-tab>
          <v-tab-item><ChallengeForm /></v-tab-item>
          <v-tab-item><ChildChallengeList /></v-tab-item>
        </v-tabs>
      </div>
    </div>

    <v-overlay absolute :value="isLoading" opacity="0.2">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mdiChevronRight } from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ChildChallengeList from './components/ChildChallengeList'
import ChallengeForm from './components/ChallengeForm'

export default {
  name: 'ChallengeDetails',

  components: {
    AppBarNavIcon,
    ChildChallengeList,
    ChallengeForm,
  },

  data() {
    return {
      icons: {
        arrowRight: mdiChevronRight,
      },
      isLoading: false,
    }
  },

  computed: {
    ...mapState({
      challenge: (state) => state.challenge.detail,
    }),
    tabItems: {
      get() {
        if (this.challenge.parent_id === null) {
          return [{ tab: 'Info' }, { tab: 'Children' }]
        }

        return [{ tab: 'Info' }]
      },
    },
  },

  methods: {
    ...mapActions({
      getChallengeDetails: 'challenge/getChallengeDetails',
    }),

    ...mapMutations({
      clearChallengeDetails: 'challenge/clearDetail',
    }),

    async getChallenge() {
      this.isLoading = true
      await this.getChallengeDetails(this.$route.params.id)
      this.isLoading = false
    },
  },

  created() {
    this.getChallenge()
  },

  destroyed() {
    this.clearChallengeDetails()
  },

  watch: {
    $route() {
      this.getChallenge()
    },
  },
}
</script>

<style lang="scss" scoped></style>
