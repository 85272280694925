<template>
  <v-dialog :value="value" max-width="60%" persistent>
    <v-card class="pa-4">
      <v-card-title class="headline">
        Share Challenge
      </v-card-title>
      <v-card-text>
        <v-combobox
          append-icon=""
          label="Enter email address separated by enter"
          v-model="form.emails"
          multiple
          allow-overflow
          chips
          :error-messages="form.$getError('emails')"
        >
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              @click:close="parent.selectItem(item)"
            >
              {{ item }}
            </v-chip>
          </template>
        </v-combobox>
      </v-card-text>
      <v-card-actions class="pa16">
        <v-btn
          @click="close"
          color="red"
          width="10%"
          dark
          depressed
          class="px-6"
          >Close</v-btn
        >
        <v-spacer />
        <v-btn
          @click="share"
          width="10%"
          depressed
          color="yellow"
          :loading="form.$busy"
          :disabled="submittable"
          >Share</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Form from '@/utils/form'
import { mapMutations, mapState } from 'vuex'

export default {
  props: {
    value: Boolean
  },

  data() {
    return {
      form: new Form({
        emails: []
      })
    }
  },

  computed: {
    ...mapState({
      challenge: state => state.challenge
    }),
    submittable() {
      return this.form.emails.length < 1
    }
  },

  methods: {
    ...mapMutations({
      callSnackbar: 'callSnackbar'
    }),

    async share() {
      this.form.$busy = true
      this.form.$clearErrors()
      let formData = new FormData()

      this.form.emails.forEach(element => {
        formData.append('emails[]', element)
      })

      await this.$api
        .post('/challenges/' + this.$route.params.id + '/share', formData)
        .then(() => {
          this.form.$busy = false
          this.callSnackbar({
            state: true,
            text: 'Successfully send invites',
            color: 'green'
          })
          this.close()
        })
        .catch(err => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors({
              emails: 'The emails must be a valid email address.'
            })
          }
        })
    },

    close() {
      this.form.$reset()
      this.$emit('close')
    }
  }
}
</script>

<style></style>
