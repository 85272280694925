<template>
  <div class="py-8 mb-12">
    <v-container>
      <v-form @submit.prevent="save">
        <div>
          <label class="text-field-label">Organisation</label>
          <v-autocomplete
            flat
            solo
            class="mt-2"
            v-model="form.organisation_id"
            :items="organisations"
            item-text="name"
            item-value="id"
            no-data-text="Organisation not found"
            label="Select an Organisation"
            :error-messages="(errors.organisation_id || []).join('')"
          ></v-autocomplete>
        </div>

        <label class="text-field-label">Mission</label>
        <v-select
          flat
          solo
          class="mt-2"
          :items="missions"
          v-model="form.mission_id"
          item-text="title"
          item-value="id"
          :error-messages="(errors.mission_id || []).join('')"
        ></v-select>

        <label class="text-field-label">Title</label>
        <v-text-field
          flat
          solo
          class="mt-2"
          v-model="form.title"
          :error-messages="(errors.title || []).join('')"
        />

        <label class="text-field-label">Tagline</label>
        <v-text-field
          flat
          solo
          class="mt-2"
          v-model="form.tagline"
          :error-messages="(errors.tagline || []).join('')"
        />

        <label class="text-field-label">Description</label>
        <v-textarea
          no-resize
          flat
          solo
          class="mt-2"
          rows="6"
          v-model="form.description"
          :error-messages="(errors.description || []).join('')"
        />

        <v-row :no-gutters="$vuetify.breakpoint.smAndDown">
          <v-col cols="12" sm="4">
            <label class="text-field-label">Start Date</label>
            <v-text-field
              flat
              solo
              class="mt-2"
              v-model="form.start_date"
              type="datetime-local"
              :error-messages="(errors.start_date || []).join('')"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <label class="text-field-label">End Date</label>
            <v-text-field
              flat
              solo
              class="mt-2"
              v-model="form.end_date"
              type="datetime-local"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <label class="text-field-label">Is Initiation</label>
            <v-checkbox v-model="form.is_initiation"></v-checkbox>
          </v-col>
        </v-row>

        <label class="text-field-label">Goal</label>
        <v-text-field
          flat
          solo
          class="mt-2"
          v-model="form.goal"
          type="number"
          :error-messages="(errors.goal || []).join('')"
        />

        <label class="text-field-label">Goal Type</label>
        <v-select
          flat
          solo
          class="mt-2"
          :items="['users', 'actions', 'custom', 'actions_counter']"
          :error-messages="(errors.goal_type || []).join('')"
          v-model="form.goal_type"
        ></v-select>

        <div v-if="form.goal_type === 'actions_counter'">
          <label class="text-field-label">Impact Question</label>
          <v-text-field
            flat
            solo
            class="mt-2"
            v-model="form.impact_question"
            :error-messages="(errors.impact_question || []).join('')"
          />
        </div>

        <label class="text-field-label">Goal Unit Description</label>
        <v-text-field
          flat
          solo
          class="mt-2"
          v-model="form.goal_unit_description"
          :error-messages="(errors.goal_unit_description || []).join('')"
        />

        <label class="text-field-label">
          Featured Video URL from Cloudinary
        </label>
        <v-text-field
          flat
          solo
          class="mt-2"
          placeholder="https://res.cloudinary.com/goodempire/video/upload/v1635238778/featured_videos/tree-hugger_d8bxiw.mp4"
          v-model="form.featured_video_url"
          :error-messages="(errors.featured_video_url || []).join('')"
        />

        <label class="text-field-label"> Featured Video Public ID </label>
        <v-text-field
          flat
          solo
          class="mt-2"
          placeholder="tree-hugger_d8bxiw"
          v-model="form.featured_video_public_id"
          :error-messages="(errors.featured_video_public_id || []).join('')"
        />

        <label class="text-field-label"> Featured Video Directory </label>
        <v-text-field
          flat
          solo
          class="mt-2"
          placeholder="featured_videos"
          v-model="form.featured_video_directory"
          :error-messages="(errors.featured_video_directory || []).join('')"
        />

        <v-row>
          <v-col cols="6">
            <label class="text-field-label"> Impact Project Id </label>
            <v-text-field
              flat
              solo
              class="mt-2"
              v-model="form.charity_id"
              @input="fetchCharity"
              :error-messages="(errors.charity_id || []).join('')"
            />
          </v-col>
          <v-col cols="6">
            <label class="text-field-label"> Impact Project </label>
            <v-text-field
              flat
              solo
              class="mt-2"
              v-model="charityName"
              :error-messages="(errors.charity_name || []).join('')"
            />
          </v-col>
        </v-row>

        <v-row :no-gutters="$vuetify.breakpoint.smAndDown">
          <v-col cols="12" sm="2">
            <label class="text-field-label">Is Public</label>
            <v-checkbox v-model="form.is_public" />
          </v-col>
          <v-col cols="8" sm="6" v-if="!isCreating">
            <label class="text-field-label">Deeplink URL</label>
            <v-text-field
              flat
              solo
              readonly
              class="mt-2"
              v-model="form.deeplink_url"
              append-icon="mdi-content-copy"
              @click:append="copyToClipboard"
              ref="deeplink"
            />
          </v-col>
          <v-col cols="4" sm="2" align-self="center" v-if="!isCreating">
            <v-btn
              class="px-6"
              height="50px"
              width="100%"
              @click="shareChallenge"
            >
              Share Via Email
            </v-btn>
          </v-col>
          <v-col cols="4" sm="2" align-self="center" v-if="!isCreating">
            <v-btn
              v-if="form.parent_id === null"
              class="px-6"
              height="50px"
              width="100%"
              @click="
                $router.replace({
                  name: 'challenge.child',
                  params: { id: form.id }
                })
              "
            >
              Create Child Challenge
            </v-btn>
          </v-col>
        </v-row>

        <div class="justify-space-between d-flex">
          <v-btn
            color="red"
            dark
            depressed
            class="px-6"
            height="40px"
            @click="confirmDialog = true"
            v-if="!isCreating"
          >
            Delete
          </v-btn>

          <v-btn
            color="yellow"
            depressed
            type="submit"
            class="px-6"
            height="40px"
            :disabled="fetchingCharity"
            :loading="loading"
          >
            Save
          </v-btn>
        </div>
      </v-form>
    </v-container>

    <v-dialog v-model="confirmDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Delete ?</v-card-title>

        <v-card-text
          >Are you sure you want to delete this challenge ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="confirmDialog = false" :loading="loading"
            >Cancel</v-btn
          >
          <v-btn color="red" text @click="performDelete()" :loading="loading"
            >Yes, Delete please</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <share-challenge-modal
      :value="openShareChallengeModal"
      @close="closeShareChallengeModal"
    />

    <child-challenge-modal
      :value="openChildChallengeModal"
      @close="closeChildChallengeModal"
    />
  </div>
</template>

<script>
import Challenge from '@/models/Challenge.js'
import Mission from '@/models/Mission.js'
import { mapMutations, mapState, mapActions } from 'vuex'
import ShareChallengeModal from '@/components/modals/ShareChallengeModal'
import ChildChallengeModal from '@/components/modals/ChildChallengeModal'
import Pbp from '@/services/pbp'
import debounce from 'lodash/debounce'

export default {
  name: 'ChallengeFormPage',

  components: {
    ShareChallengeModal,
    ChildChallengeModal
  },

  data() {
    return {
      missions: [],
      form: new Challenge({}),
      loading: false,
      confirmDialog: false,
      errors: {},
      openShareChallengeModal: false,
      openChildChallengeModal: false,
      charityName: '',
      fetchingCharity: false
    }
  },

  computed: {
    ...mapState({
      challenge: state => state.challenge.detail,
      organisations: state => state.organisation.list
    }),
    isCreating() {
      return !this.$route.params.id || this.$route.name === 'challenge.child'
    }
  },

  methods: {
    ...mapMutations({
      callSnackbar: 'callSnackbar'
    }),

    ...mapActions({
      getChallengeDetails: 'challenge/getChallengeDetails',
      getOrganisations: 'organisation/getOrganisations'
    }),

    async loadMissions() {
      this.missions = (await Mission.orderBy('title').get()).data
    },
    async loadForm() {
      if (this.$route.params.id) {
        await this.getOrganisations({ type: 'all' })
        await this.getChallengeDetails(this.$route.params.id)
        this.form = new Challenge(this.challenge)
        if (this.form.charity_id) {
          this.getCharity()
        }
      }
    },

    async save() {
      if (!this.fetchingCharity) {
        try {
          this.loading = true

          if (this.form.goal_type !== 'actions_counter') {
            this.form.impact_question = null
          }

          if (this.$route.name === 'challenge.child') {
            this.form.parent_id = this.$route.params.id
          }

          const { data } =
            this.$route.name === 'challenge.child'
              ? (
                  await this.$api.post(
                    '/challenges/' + this.$route.params.id + '/child',
                    this.form
                  )
                ).data
              : await this.form.save()

          this.errors = {}
          this.form = new Challenge(data)
          this.loading = false

          this.callSnackbar({
            state: true,
            text: `Successfully ${
              this.isCreating ? 'added' : 'updated'
            } challenge`,
            color: 'green'
          })

          if (this.isCreating) {
            this.$router.replace({
              name: 'challenge.details',
              params: { id: data.id }
            })
          }
        } catch (error) {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
        } finally {
          this.loading = false
        }
      }
    },
    async performDelete() {
      this.loading = true
      await this.form.delete()
      this.loading = false
      this.confirmDialog = false
      this.$router.replace({ name: 'challenges' })
    },
    async copyToClipboard() {
      navigator.clipboard.writeText(this.form.deeplink_url)
      this.callSnackbar({
        state: true,
        text: 'Copied',
        color: 'green'
      })
    },
    shareChallenge() {
      this.openShareChallengeModal = true
    },
    closeShareChallengeModal() {
      this.openShareChallengeModal = false
    },
    childChallenge() {
      this.openChildChallengeModal = true
    },
    closeChildChallengeModal() {
      this.openChildChallengeModal = false
    },
    async getCharity() {
      this.errors.charity_id = null
      this.fetchingCharity = true
      this.charityName = 'Fetching...'
      await Pbp.get('/organisations/' + this.form.charity_id)
        .then(response => {
          this.charityName = response.data.data.name
        })
        .catch(() => {
          if (
            this.challenge.charity_id !== null &&
            this.form.charity_id !== null
          ) {
            this.form.charity_id = this.challenge.charity_id
            this.getCharity()
          } else {
            this.charityName = ''
            this.form.charity_id = null
          }
          this.errors = {
            charity_id: ['Invalid ID.']
          }
        })
      this.fetchingCharity = false
    },
    fetchCharity: debounce(function() {
      this.getCharity()
    }, 600)
  },

  mounted() {
    this.loadMissions()
    this.loadForm()
  },

  watch: {
    $route: function() {
      this.loadMissions()
      this.loadForm()
    },
    'form.charity_id': function(newValue) {
      if (newValue) {
        this.fetchingCharity = true
      }
    }
  }
}
</script>

<style scoped></style>
