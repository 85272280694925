<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <router-link class="text-decoration--none" :to="{ name: 'challenges' }"
          ><div>Challenge</div></router-link
        >
        <v-icon>{{ icons.arrowRight }}</v-icon>
        {{ title }}
      </v-toolbar-title>
    </v-app-bar>

    <ChallengeForm />
  </div>
</template>

<script>
import { mdiChevronRight } from '@mdi/js'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ChallengeForm from './components/ChallengeForm'

export default {
  name: 'ChallengeDetails',

  components: {
    AppBarNavIcon,
    ChallengeForm,
  },

  data() {
    return {
      icons: {
        arrowRight: mdiChevronRight,
      },
    }
  },

  computed: {
    title() {
      if (this.$route.name === 'challenge.child') {
        return 'Add New Child Challenge'
      }

      return 'Add New Challenge'
    },
  },
}
</script>

<style scoped></style>
